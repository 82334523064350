import { CartItem } from '@business/gql/graphql';
import { cn } from '@ngg/storefront-utils';
import { Dictionary } from '@ts/dictionary';
import { Heading } from '@ui/components/Heading/Heading';
import Paragraph from '@ui/components/Paragraph/Paragraph';
import { ComponentProps } from 'react';

export default function StockWatchStatus({
  stock,
  onRemove,
  onUpdate,
  className,
  dictionary,
}: {
  stock: CartItem['stockWatchStatus'];
  onRemove: () => void;
  onUpdate: (quantity: number) => void;
  dictionary?: Dictionary;
} & ComponentProps<'div'>) {
  if (!stock) return null;
  const { ableToComplete, currentOnHandValue } = stock;
  if (ableToComplete) return null;
  return (
    <div
      className={cn(
        'grid grid-flow-col grid-cols-[1fr,auto] grid-rows-[auto,auto] items-center gap-x-1 bg-red-50 px-4 py-2 text-left text-sm text-red-600',
        className,
      )}>
      <Heading as="h3" className="font-bold">
        {currentOnHandValue
          ? dictionary?.onlyXLeftInStock.replace(
              '%X%',
              currentOnHandValue.toString(),
            )
          : dictionary?.notInStock}
      </Heading>
      <Paragraph size="xsmall">
        {currentOnHandValue
          ? dictionary?.updateQuantityToProceed
          : dictionary?.productIsSoldOut}
      </Paragraph>
      <button
        onClick={() =>
          currentOnHandValue ? onUpdate(currentOnHandValue) : onRemove()
        }
        className="col-start-2 row-span-2 border border-red-600 px-2 font-sans">
        {currentOnHandValue
          ? dictionary?.update ?? 'Uppdatera'
          : dictionary?.remove ?? 'Ta bort'}
      </button>
    </div>
  );
}
