'use client';

import Image from 'next/image';
import type { ImageProps } from 'next/image';
import { cn } from '@ngg/storefront-utils';
import { envConfig } from '@env/config';

type CartImageProps = ImageProps & {
  imageKey: string;
};

export default function CartImage({
  imageKey,
  className,
  alt,
  ...props
}: Omit<CartImageProps, 'src'>) {
  return (
    <Image
      className={cn('object-contain', className)}
      src={`${envConfig.NEXT_PUBLIC_NORCE_IMAGE_CDN}/${imageKey}?w=200&h=200&mode=max`}
      width={72}
      height={72}
      alt={alt}
      {...props}
    />
  );
}
