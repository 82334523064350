'use client';

import { CartUpdateMutationFunc } from '@utils/hooks/cart/useCartUpdate';
import { Quantity as QuantityComponent } from '@ngg/components';
import React, { useMemo } from 'react';
import { MinusIcon, PlusIcon } from '@ngg/icons';
import useSetProductQuantity from '@utils/hooks/useSetProductQuantity';
import { CartRemoveMutationFunc } from '@utils/hooks/cart/useCartRemove';
import { cn } from '@ngg/storefront-utils';
import { formatPrice } from '@utils/currency/formatPrice';
import { CartItem } from '@business/gql/graphql';
import { isCheckoutDisabled } from '@ui/components/Stock/Stock.utils';

type Props = {
  item: CartItem;
  update: CartUpdateMutationFunc;
  remove: CartRemoveMutationFunc;
  disabled?: boolean;
  onQuantityChange?: (quantity: number, id: string) => void;
  bottomContent?:
    | React.ReactNode
    | (({ quantity }: { quantity: number }) => React.ReactNode);
  id?: string;
  className?: string;
};

const Quantity = (props: Props) => {
  const {
    item,
    update,
    remove,
    disabled = false,
    id,
    bottomContent,
    onQuantityChange,
    className,
  } = props;
  const { quantity, partNo } = item;
  const {
    increase,
    decrease,
    quantity: productQuantity,
  } = useSetProductQuantity({
    quantity,
    id,
    partNo,
    update,
    remove,
    onQuantityChange,
  });

  const BottomContent = useMemo(() => {
    if (typeof bottomContent === 'function') {
      return bottomContent({ quantity: productQuantity });
    }
    return bottomContent;
  }, [bottomContent, productQuantity]);

  return (
    <div className={cn(['flex pt-2', className])}>
      <div className="flex-1">
        <div className="w-20">
          <QuantityComponent
            dense
            readOnly
            disabled={disabled}
            value={productQuantity}
            onIncrease={() => (isCheckoutDisabled(item) ? null : increase)}
            onDecrease={decrease}
            DecreaseIcon={<MinusIcon className="text-xs" />}
            IncreaseIcon={<PlusIcon className="text-xs" />}
            classNames={{
              decreaseButton:
                'border-r border-gray-150 flex basis-1/3 items-center justify-center',
              increaseButton: cn(
                'border-l border-gray-150 flex basis-1/3 items-center justify-center',
                isCheckoutDisabled(item) && 'cursor-not-allowed opacity-50',
              ),
              wrapper: 'h-6 border-gray-150 hr-x font-serif',
              input: 'basis-1/3 rounded-none',
            }}
          />
        </div>
      </div>
      {BottomContent}
    </div>
  );
};

Quantity.displayName = 'Quantity';

export default Quantity;
